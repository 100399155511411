import Axios from "axios";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class TurnosService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async listTurnos () {
		const response = await this.axios.get("turnos");
		return response.data;
	}

	async obterTurnoAtual () {
		const response = await this.axios.get("turnos/turnoAtual");
		return response.data;
	}
}
