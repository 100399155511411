import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";

import SubRouter from "./views/SubRouter.vue";
import Auth from "./views/Auth.vue";
import { AuthService } from "./services/auth";
import comumService from "./services/comum";
import { encontraTelaComPermissao } from "./helpers/permissions";

Vue.use(Router);

const BASE_TITLE = "TCS MMI Trends";
const TITLE_SEPARATOR = " | ";
const authService = new AuthService();

const router = new Router({
	mode: "history",

	routes: [
		{
			path: "/auth",
			name: "auth",
			meta: { noRedirect: true },
			component: Auth
		},
		{
			path: "/",
			name: "home",
			meta: {
				title: "Tendência on-line",
				perms: ["RLT_TENDENCIA_ONLINE"]
			},
			component: () => import("./views/TendenciaOnline.vue")
		},
		{
			path: "/tendenciahistorica",
			name: "tendencia_historica",
			meta: {
				title: "Tendência histórica",
				perms: ["RLT_TENDENCIA_HISTORICA"]
			},
			component: () => import("./views/TendenciaHistorica.vue")
		},
		{
			path: "/grupos",
			meta: {
				title: "Grupos"
			},
			component: SubRouter,
			children: [
				{
					path: "",
					name: "grupos",
					meta: {
						title: "Gestor de grupos",
						perms: ["GER_S_GRUPOS_VARIAVEIS"]
					},
					component: () => import("./views/Grupos/List.vue")
				}
			]
		},
		{
			path: "/variaveis",
			meta: {
				title: "Variáveis"
			},
			component: SubRouter,
			children: [
				{
					path: "",
					name: "variaveis",
					meta: {
						title: "Gestor de variáveis",
						perms: ["GER_S_VARIAVEIS"]
					},
					component: () => import("./views/Variaveis/List.vue")
				},
				{
					path: "",
					name: "",
					meta: {
						title: "Adicionar variável",
						perms: [],
						noRedirect: true
					},
					component: () => import("./components/Edit.vue")
				},
				{
					path: "",
					name: "",
					meta: {
						title: "Editar variável",
						perms: [],
						noRedirect: true
					},
					component: () => import("./components/Edit.vue")
				}
			]
		},

		// ERROS
		{
			name: "403",
			path: "/forbidden",
			meta: {
				title: "403 - Permissão insuficiente",
				perms: [],
				noRedirect: true
			},
			component: () => import("./views/403.vue")
		},

		// 404 (precisa ser a última rota deste arquivo)
		{
			name: "404",
			path: "*",
			meta: {
				title: "404 - Página não encontrada",
				perms: [],
				noRedirect: true
			},
			component: () => import("./views/404.vue")
		}
	]
});

router.beforeEach(async (to, from, next) => {
	// Obtém os metadados da rota de destino
	const meta = to.meta || {};

	// Altera título da janela de acordo com a página acessada
	document.title = (to.meta.title ? to.meta.title + TITLE_SEPARATOR : "") + BASE_TITLE;

	// Traz as permissões inerentes ao usuário
	let userPermissions = [];
	if (localStorage.params)
		userPermissions = (JSON.parse(localStorage.params).grupoUsuario).split(",");

	// Controle de permissões de acesso
	const permissions = meta.perms || [];
	if (permissions.length == 0) {
		// Página pública
		next();
	} else if (!(await authService.isLoggedIn())) {
		// Não logado
		let pageToBack = window.location.pathname;
		pageToBack = `&pageToBack=${pageToBack}`;
		window.location.href = `${await comumService.getComumUrl()}/#!/login?redirect=${window.location.origin}&type=vue${pageToBack}`;
	} else {
		let autorizado = false;
		for (const perm of permissions) {
			if (userPermissions.indexOf(perm) !== -1) {
				// Acesso autorizado
				autorizado = true;
				break;
			}
		}

		if (autorizado)
			return next();

		// A ideia é procurar uma página que o usuário possua permissão de acesso.
		// Caso não possua nenhuma permissão, ele será redirecionado
		// para uma página padrão (403).
		const result = encontraTelaComPermissao(userPermissions, router.options.routes);
		router.push({ name: result }).catch(e => {
			if (e.type != 8) console.log(e);
		});
	}
});

// Tooltips
router.afterEach(() => {
	const $ = require("jquery");
	$(".tooltip[role=tooltip]").tooltip("hide");
	setTimeout(() => {
		const $tooltips = $('[data-toggle="tooltip"]');
		if (window.innerWidth <= 790)
			$tooltips.tooltip("disable");
		else
			$tooltips.tooltip("enable");
	});
});

// Loading
NProgress.configure({ trickleRate: 0.1, trickleSpeed: 800, showSpinner: false });

router.beforeEach((to, from, next) => {
	NProgress.start();
	next();
});

router.afterEach(() => {
	NProgress.done();
});

export default router;
