import buildUrl from "build-url";

import configurable_base_url from "@/api_base_url.json";

//'http://10.0.0.207:3000/api';
let BASE_URL = configurable_base_url || `${document.location.protocol}//${document.location.hostname}:3000/api`;
if (BASE_URL.startsWith("/"))
	BASE_URL = `${document.location.protocol}//${document.location.host}${BASE_URL}`;

const listApi = url => ({
	list (page, results, q, order, asc) {
		return buildUrl(url, {
			path: "list",
			queryParams: { page, results, q, order, asc: +!(asc === false) }
		});
	}
});

const basic = url => ({
	_url: url,
	...listApi(url),
	get (id) {
		return url + "/get/" + id;
	},
	get create () {
		return url + "/create";
	},
	get update () {
		return url + "/update";
	},
	get destroy () {
		return url + "/destroy";
	}
});

export default Object.freeze({
	baseURL: BASE_URL,
	get v1 () {
		return {
			_url: this.baseURL + "/v1",
			get info () {
				const _url = this._url + "/info";
				return {
					get versao () {
						return _url + "/versao";
					},
					get timestamp () {
						return _url + "/timestamp";
					}
				};
			},

			get area () {
				const _url = this._url + "/area";
				return {
					...basic(_url),
					getPath (areas) {
						return _url + "/get-path?areas=" + JSON.stringify(areas);
					},
					listRecursive (q) {
						return _url + "/list-recursive?q=" + encodeURIComponent(q);
					},
					listRecursiveByIds (areas) {
						return _url + "/list-recursive?areas=" + JSON.stringify(areas);
					}
				};
			},

			get variavel () {
				const _url = this._url + "/variavel";
				return {
					...basic(_url),
					listByAreas (areas) {
						return _url + "/list-by-areas?areas=" + JSON.stringify(areas);
					}
				};
			},

			get tipoVariavel () {
				const _url = this._url + "/tipo-variavel";
				return listApi(_url);
			},


			get uuid () {
				return this._url + "/uuid";
			},

			get medicao () {
				const _url = this._url + "/medicao";
				return {
					_url,
					get list () {
						return this._url + "/list";
					},
					get (id) {
						return this._url + "/get/" + id;
					}
				};
			},

			get graficos () {
				const _url = this._url + "/graficos";
				return {
					_url,
					tendencia (dataHoraInicial, dataHoraFinal, idDetectores, intervalo = undefined, numeroPontos = 300, tabela) {
						return buildUrl(_url, {
							path: "tendencia",
							queryParams: {
								dataHoraInicial: +dataHoraInicial,
								dataHoraFinal: +dataHoraFinal,
								idDetectores: JSON.stringify(idDetectores),
								intervalo,
								numeroPontos: intervalo ? undefined : numeroPontos,
								tabela
							}
						});
					}
				};
			}
		};
	},

	get socket () {
		const trueBaseURL = this.baseURL.split("://");
		if (trueBaseURL[0] === "https")
			trueBaseURL[0] = "wss";
		else
			trueBaseURL[0] = "ws";

		const _url = trueBaseURL.join("://") + "/socket";
		return {
			_url,
			medicoes (token, idDetector) {
				return this._url + "/medicoes?idDetector=" + idDetector + "&bearer=" + token;
			}
		};
	},

	get changelog () {
		return this.baseURL + "/changelog.html";
	}
});
