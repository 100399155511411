const dataTables = ["Grupo", "Variavel"];

function genState () {
	const obj = {};
	for (const dataTable of dataTables) {
		obj["dataTable" + dataTable] = {
			sortBy: 0,
			sortAsc: true,
			query: ""
		};
	}
	return obj;
}

function genMutations () {
	const obj = {};
	for (const dataTable of dataTables) {
		obj["setStateDataTable" + dataTable] = function setStateDataTable (state, sort) {
			state["dataTable" + dataTable] = sort;
		};
	}
	return obj;
}

export default {
	namespaced: true,
	state: genState(),
	mutations: genMutations()
};
