<template>
	<div id="app">
		<!-- <div id="available-data">AMBIENTE DE TESTES. Dados disponíveis apenas para o mês de Janeiro de 2024.</div> -->
		<nav-bar
			@menu-button-click="toggleSideBar"
			@change-max-width="updateSideBarMaxWidth"
			:showBackButton="showBackButton"
			@back-button-click="goBack"
		/>
		<side-bar
			:hidden="sideBarHidden || noPermission"
			:max-width="sideBarMaxWidth"
			@link="sideBarLinkClicked"
			:links="parsedLinks"
			@resize="sideBarResized"
		/>
		<router-view/>
		<vue-snotify></vue-snotify>
		<div id="app-bottom-padding"></div>
	</div>
</template>

<script>
	import "@/assets/css/mmi.css";
	import "@/assets/css/style.css";
	import "@/assets/scss/trends.scss";

	import NavBar from "@/components/NavBar.vue";
	import SideBar from "@/components/SideBar.vue";

	import { mapMutations } from "vuex";
	import { copy } from "./helpers/common";

	let itensHistorico = 0;

	const isMobile = () => window.innerWidth <= 790;

	export default {
		name: "App",
		components: {
			NavBar,
			SideBar
		},

		data () {
			return {
				sideBarWidth: 0,
				sideBarHidden: true,
				sideBarMaxWidth: 0,
				initialized: false,
				links: [
					{
						to: { name: "home" },
						name: "Tendência on-line",
						icon: "line-chart",
						showPerms: ["RLT_TENDENCIA_ONLINE"]
					},
					{
						to: { name: "tendencia_historica" },
						name: "Tendência histórica",
						icon: "archive",
						showPerms: ["RLT_TENDENCIA_HISTORICA"]
					},
					{
						name: "Gerenciamento",
						icon: "sliders",
						showPerms: ["GER_S_GRUPOS_VARIAVEIS"],
						children: [
							{
								to: { name: "grupos" },
								name: "Gestor de grupos",
								icon: "layers",
								showPerms: ["GER_S_GRUPOS_VARIAVEIS"]
							},
							{
								to: { name: "variaveis" },
								name: "Gestor de variáveis",
								icon: "monitor",
								showPerms: ["GER_S_VARIAVEIS"]
							}
						]
					}
				],
				parsedLinks: []
			};
		},

		computed: {
			showBackButton () {
				const routes = ["home"];
				return !routes.includes(this.$route.name);
			},

			noPermission () {
				return [].some((el) => el === this.$route.name);
			}
		},

		watch: {
			noPermission () {
				this.sideBarHidden = this.noPermission;
				this.updateSideBarWidth(
					this.sideBarHidden || isMobile() ? 0 : this.sideBarWidth
				);
			}
		},

		methods: {
			goBack () {
				if (itensHistorico > 1)
					return this.$router.back();

				let found = false;
				for (let i = this.$route.matched.length - 1; i >= 0; i--) {
					const match = this.$route.matched[i];
					if (match.parent && match.parent.path + "/" !== match.path) {
						itensHistorico--;
						this.$router.push(match.parent.path);
						found = true;
						break;
					}
				}

				if (!found)
					this.$router.push({ name: "home" });
			},

			toggleSideBar () {
				if (this.noPermission) return;

				this.sideBarHidden = !this.sideBarHidden;
				this.updateSideBarWidth(
					this.sideBarHidden || isMobile() ? 0 : this.sideBarWidth
				);
			},

			sideBarResized (w) {
				this.sideBarWidth = w;
				this.updateSideBarWidth(
					this.sideBarHidden || isMobile() || this.noPermission ? 0 : w
				);
			},

			updateSideBarMaxWidth (w) {
				this.sideBarMaxWidth = w;
				if (!this.initialized) {
					// Detacta celular e encolhe a barra
					this.sideBarHidden = w < 100;
					this.initialized = true;
					this.sideBarWidth = 72;
					this.updateSideBarWidth(
						(this.sideBarHidden || isMobile() || this.noPermission) ? 0 : this.sideBarWidth
					);
				}
			},

			sideBarLinkClicked () {
				if (isMobile())
					this.sideBarHidden = !this.sideBarHidden;
			},

			_updateLinks (links) {
				for (const link of links) {
					if (link.children)
						link.children = this._updateLinks(link.children);
				}

				return links.filter(
					ln => !ln.showPerms || !ln.children || ln.children.length > 0
				);
			},

			updateLinks () {
				const links = copy(this.links);
				this.parsedLinks = this._updateLinks(links);
			},

			...mapMutations(["updateSideBarWidth"])
		},

		created () {
			this.updateLinks();

			this.$router.afterEach((to, from) => {
				if (from.name === null || to.name === "home")
					itensHistorico = 0;
				else
					itensHistorico++;
			});
		}
	};
</script>

<style scoped>
	#app-bottom-padding {
		height: 80px;
	}

	@media (max-width: 790px) {
		#app-bottom-padding {
			max-height: 0px;
		}
	}
</style>

<style>
	.container {
		max-width: 98%;
	}

	#available-data {
		position: fixed;
		z-index: 999;
		bottom: 0px;
		margin: 0;
		padding: 4px;
		width: 100%;
		text-align: center;
		font-weight: bold;
		color: #383d41;
		background-color: #e2e3e5;
		border: 1px solid #d6d8db;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	}

	body:has(> #app > #available-data) {
		padding-bottom: 40px;
	}
</style>
