import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import dataTables from "./modules/dataTables";

const CACHE_LIMIT = 60 * 1000;

const store = new Vuex.Store({
	strict: process.env.NODE_ENV === "development",
	modules: { dataTables },
	state: {
		fontScale: +(localStorage.getItem("trends/fonte")) || 1,
		sideBarWidth: 0,
		cacheGrupoHierarquia: new Map()
	},
	mutations: {
		updateSideBarWidth (state, w) {
			state.sideBarWidth = w;
		},
		addGrupoHierarquia (state, { idGrupo, nomeGrupo } = {}) {
			state.cacheGrupoHierarquia.set(idGrupo, { nome: nomeGrupo, data: Date.now() });
		},
		setFontScale (state, scale) {
			if (!+scale) return;
			if (scale > 3) scale = 3;
			if (scale < 1) scale = 1;
			localStorage.setItem("trends/fonte", scale);
			state.fontScale = scale;
		}
	},
	getters: {
		getGrupoHierarquia: state => idGrupo => {
			const cache = state.cacheGrupoHierarquia.get(idGrupo);
			if (cache && Date.now() - cache.data < CACHE_LIMIT) {
				return cache.nome;
			} else {
				return undefined;
			}
		}
	}
});

export default store;
